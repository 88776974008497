interface ExperienceProps {
    date: string;
    title: string;
    description: string; 
  }

  export const experianceList: ExperienceProps[] = [
    {
        date: "08.2024 - Present",
        title: "Web Developer, Codecontrol Oy, Pori",
        description: "Designing web application layouts in Figma, developing front-end in React Typescript with Tailwind CSS and Material UI. Backend development with C# .Net and Entity Framework Core using PostgreSQL for Web API solution.",
        
      },
      {
        date: "03.2024 - 09.2024",
        title: "LearnIT - Coding and Finnish Language, BearIT Tampere",
        description: "Collaborated as part of a team to build the Mittwatt application in Agile working methodology. Developed an electricity price prediction model in Python. Gained proficiency in Bitbucket, PostgreSQL, JavaScript, teamwork, and projectmanagement.",
        
      },
      {
        date: "02.2024 - 03.2024",
        title: "Frontend Developer Intern, Tuspe Design Oy",
        description: "Frontend development using SvelteKit and Tailwind CSS.",
        
      },
      {
        date: "08.2023 - 01.2024",
        title: "Research Internship, SAMK Pori",
        description: "Data analysis and implementing machine learning models in synthetic LIBS datasets. Designing web apps in ReactJS.",
        
      },
      {
        date: "03.2020 - 10.2020",
        title: "Technical Documentation (Python)",
        description:
          'Major contribution in chapters "Data Wrangling" and "Model Development and Evaluation" in book "Hands-On Exploratory Data Analysis with Python eBook" by Packt Publication.',
      },
      {
        date: "04.2019 - 01.2020",
        title: "Web Developer, Softedge Nepal Pvt. Ltd.",
        description: "Web Developer. Worked with web-based applications in ASP .Net MVC framework.",
        
      },
      
  ]